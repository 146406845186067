import React from "react"
import { useApp } from "../../hooks/useApp"
import { getAdditionalText } from "../../utils/getAdditionalText"

export const withCareer = Component => ({
  name = "Career",
  page,
  template,
}) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { position, location, introduction, salary, content, email } =
    page || {}

  const { additionalBreadCrumbText } = template || {}

  const {
    template: {
      career: {
        additionalIntroductionTitleText,
        additionalLocationTitleText,
        additionalSalaryTitleText,
        additionalApplyButtonText,
        additionalFullDescriptionTitleText,
      },
    },
  } = getAdditionalText()

  Component.displayName = name
  return (
    <Component
      url={routes.CAREER}
      position={position}
      location={location}
      introduction={introduction}
      salary={salary}
      content={content}
      email={email}
      additionalBreadCrumbText={additionalBreadCrumbText}
      additionalIntroductionTitleText={additionalIntroductionTitleText}
      additionalLocationTitleText={additionalLocationTitleText}
      additionalSalaryTitleText={additionalSalaryTitleText}
      additionalApplyButtonText={additionalApplyButtonText}
      additionalFullDescriptionTitleText={additionalFullDescriptionTitleText}
    />
  )
}
