import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { StyledButton } from "../Styled/Button"
import { StyledContainer } from "../Styled/Container"

export const Wrapper = styled(StyledContainer)`
  ${tw`py-4 md:pt-8 md:pb-12`}
`

export const BreadCrumbs = styled(Link)`
  ${tw`text-14 leading-1.71 text-navy text-opacity-60`}
`

export const Position = tw.h1`
  text-35 leading-1.28 tracking-10 uppercase font-bold
`

export const Details = tw.div`
  flex flex-col md:flex-row
`

export const Detail = tw.div`
  flex flex-col mb-4 w-full
`

export const SubTitle = tw.h3`
  text-16 leading-1.25 tracking-10 font-bold mb-1-6 uppercase
`

export const Apply = styled(StyledButton)``

export const Divider = tw.hr`
  h-0-2 text-navy text-opacity-20 my-4 md:my-6
`
