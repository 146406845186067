import React from "react"
import { graphql } from "gatsby"

import { Career as Template } from "../components/Career/Career"

export const query = graphql`
  query Test($id: String!) {
    page: sanityCareer(_id: { eq: $id }) {
      title
      slug {
        current
      }
      position
      introduction
      type
      salary
      location
      email
      content: _rawContent(resolveReferences: { maxDepth: 8 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
    template: sanityTemplateCareer {
      additionalBreadCrumbText
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
