import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const withCareerPopup = Component => ({
  name = "CareerPopup",
  email,
}) => {
  const { careerTemplate } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_CAREER_POPUP {
      careerTemplate: sanityTemplateCareer {
        additionalApplyPopupTitle
        additionalApplyPopupDescription
      }
    }
  `)

  const { additionalApplyPopupTitle, additionalApplyPopupDescription } =
    careerTemplate || {}
  Component.displayName = name
  return (
    <Component
      email={email}
      additionalApplyPopupTitle={additionalApplyPopupTitle}
      additionalApplyPopupDescription={additionalApplyPopupDescription}
    />
  )
}
