import React from "react"

import { withCareer } from "./withCareer"
import {
  Wrapper,
  BreadCrumbs,
  Position,
  Divider,
  Details,
  Detail,
  SubTitle,
  Apply,
} from "./CareerStyles"
import { Body } from "../Styled/Text"
import { RichText } from "../RichText/RichText"
import { Modal, ModalContentsWithLayout, ModalOpenButton } from "../Modal/Modal"
import { CareerPopup } from "../CareerPopup/CareerPopup"

export const Career = withCareer(
  ({
    url,
    position,
    location,
    introduction,
    salary,
    content,
    email,
    additionalBreadCrumbText = "Careers",
    additionalIntroductionTitleText,
    additionalLocationTitleText,
    additionalSalaryTitleText,
    additionalApplyButtonText,
    additionalFullDescriptionTitleText,
  }) => (
    <Wrapper width="md">
      <Modal>
        <BreadCrumbs to={url} title={additionalBreadCrumbText}>
          {additionalBreadCrumbText} /
        </BreadCrumbs>
        <Position>{position}</Position>
        <Divider />
        <Detail>
          <SubTitle>{additionalIntroductionTitleText}</SubTitle>
          <Body>{introduction}</Body>
        </Detail>
        <Details>
          <Detail>
            <SubTitle>{additionalLocationTitleText}</SubTitle>
            <Body>{location}</Body>
          </Detail>
          <Detail>
            {salary?.length > 0 && (
              <>
                <SubTitle>{additionalSalaryTitleText}</SubTitle>
                <Body>{salary}</Body>
              </>
            )}
          </Detail>
        </Details>
        <ModalOpenButton>
          <Apply size="primary">{additionalApplyButtonText}</Apply>
        </ModalOpenButton>
        {content?.length > 0 ? (
          <>
            <Divider />
            <Detail>
              <SubTitle>{additionalFullDescriptionTitleText}</SubTitle>
              <RichText>{content}</RichText>
            </Detail>
            <ModalOpenButton>
              <Apply size="primary">{additionalApplyButtonText}</Apply>
            </ModalOpenButton>
          </>
        ) : null}
        <ModalContentsWithLayout layout="career">
          <CareerPopup email={email} />
        </ModalContentsWithLayout>
      </Modal>
    </Wrapper>
  ),
)
