import React from "react"
import { ModalDismissButton } from "../Modal/Modal"
import { StyledButton } from "../Styled/Button"
import { Body } from "../Styled/Text"
import { SubTitle } from "./CareerPopupStyles"
import { withCareerPopup } from "./withCareerPopup"

export const CareerPopup = withCareerPopup(
  ({ additionalApplyPopupTitle, additionalApplyPopupDescription, email }) => (
    <div>
      <SubTitle>{additionalApplyPopupTitle}</SubTitle>
      <Body spacing={"default"}>{additionalApplyPopupDescription}</Body>
      <Body spacing={"default"}>{email}</Body>
      <ModalDismissButton>
        <StyledButton size="primary">Close</StyledButton>
      </ModalDismissButton>
    </div>
  ),
)
